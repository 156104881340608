import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { addMinutes, useCountdown } from "src/lib/hooks";

const CountDownTimer = ({ fn, text = "Resend OTP" }) => {
  //States ********************************************** //
  const [ended, setEnded] = useState(Cookies.get("end__") ? true : false);
  const [endDate, setEndDate] = useState(Cookies.get("end__"));
  const [days, hours, minutes, seconds] = useCountdown(endDate);
  //States ********************************************** //

  const handleRunningFN = async () => {
    await fn((time) => {
      let date = addMinutes(new Date(time || null));
      Cookies.set("end__", date);
      setEndDate(date);
      setTimeout(() => {
        setEnded(true);
      }, 1000);
    });
  };

  useEffect(() => {
    if (minutes < 1 && seconds < 1 && Cookies.get("end__")) {
      // Cookies.remove("end__");
      setEnded(false);
    }
  }, [minutes, seconds]);

  useEffect(() => {
    setEndDate(Cookies.get("end__"));
  }, [Cookies.get("try_resend")]);

  return (
    <div className="countdown-timer">
      <div
        className={`resend text-center ${ended ? "text-muted" : ""}`}
        onClick={!ended ? () => handleRunningFN() : () => {}}
      >
        {text}{" "}
        {ended ? (
          <>
            ({minutes}:
            {seconds > 0 ? `${seconds < 10 ? "0" : ""}${seconds}` : null})
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CountDownTimer;
