import { useState } from "react";

//Utils
// import OtpInput from "react-otp-input";
import { useRouter } from "next/router";
import OtpInput from "react18-input-otp";
import CountDownTimer from "src/global/countdown-timer/countdown-timer";
import { useStore } from "src/lib/store";
import { AsyncAPI } from "src/lib/utils";
import Cookies from "js-cookie";
import { addMinutes } from "src/lib/hooks";
import { link_user_setting_verify_email } from "@components/page_url";

const OtpComp = ({
  phone,
  message,
  isSettingPage,
  type,
  className = "",
  callback,
  email,
}) => {
  //Utils
  const Router = useRouter();
  //States
  const [otp, setOtp] = useState(null);
  const [isError, setIsErorr] = useState(false);
  const { setLoading, setToast } = useStore((state) => state);

  //Functions
  const handleChange = async (value) => {
    setIsErorr(false);
    if (value.length <= 6) {
      setOtp(value);
      if (value.length === 6) {
        setLoading(true);
        let variables = {
          phone: phone,
          token: value,
          country_code: "62",
        };
        if (isSettingPage) variables = { ...variables, method: type, email };
        let { data, errors } = await AsyncAPI({
          method: "POST",
          variables,
          url: isSettingPage ? "/verify-otp" : "/validate-otp",
          auth: isSettingPage,
        });
        if (isSettingPage && data?.success) {
          let date = addMinutes(new Date(), 1);
          Cookies.set("end__", date);
          Router.push(
            {
              pathname: link_user_setting_verify_email,
              query: { email },
            },
            link_user_setting_verify_email
          );
          setToast({
            isToast: true,
            message: data?.message,
            status: "success",
          });
        } else {
          if (data?.access_token) {
            // Cookies.remove("end__");
            // Cookies.remove("try_resend");
            let { access_token, pin_token } = data;
            Cookies.set(process.env.NEXT_PUBLIC_PIN_TOKEN, pin_token, {
              expires: 2,
            });
            Cookies.set(process.env.NEXT_PUBLIC_ACCESS_TOKEN, access_token, {
              expires: 2,
            });
            if (callback) return callback();
            return window.location.reload();
          } else {
            setLoading(false);
            setOtp(null);
            setIsErorr(true);
          }
        }
      }
    }
  };
  // 255512;

  const handleSendOtp = async (cb) => {
    let { data, errors } = await AsyncAPI({
      method: "POST",
      variables: {
        phone,
        country_code: "62",
      },
      url: "/resend-otp",
    });
    if (data?.success) {
      setToast({ isToast: true, message: data?.message, status: "success" });
      if (cb) cb(data?.data?.valid_at);
    } else {
      if (cb) cb(errors?.response?.data?.data?.valid_at);
      setToast({
        isToast: true,
        message: errors?.response?.data?.message || errors?.message,
        status: "error",
      });
    }
  };

  return (
    <div className={`sc-otp ${className}`}>
      <p className="title text-md-center">verify your account</p>
      <div className="message-wrapper">
        <p className="message">{message}</p>
      </div>
      <div className="otp-input text-md-center py-2">
        <OtpInput
          isInputNum={true}
          containerStyle="container-otp"
          inputStyle="input-otp"
          focusStyle="focus-otp"
          errorStyle={{ border: "1px solid #EB5757" }}
          value={otp}
          onChange={handleChange}
          numInputs={6}
          hasErrored={isError}
          shouldAutoFocus
        />
      </div>
      {isError ? (
        <p className="error-text text-center mt-1">
          Invalid OTP code, please enter the latest one
        </p>
      ) : null}
      <div className="mt-5">
        <CountDownTimer fn={(cb) => handleSendOtp(cb)} />
      </div>
    </div>
  );
};

// const CountDownTimer = ({ fn }) => {
//   //States ********************************************** //
//   const [ended, setEnded] = useState(Cookies.get("end__") ? true : false);
//   const [endDate, setEndDate] = useState(Cookies.get("end__"));
//   const [days, hours, minutes, seconds] = useCountdown(endDate);
//   //States ********************************************** //

//   const handleRunningFN = async () => {
//     await fn(() => {
//       let date = addMinutes(new Date(), 1);
//       Cookies.set("end__", date);
//       setEndDate(date);
//       setTimeout(() => {
//         setEnded(true);
//       }, 1000);
//     });
//   };

//   useEffect(() => {
//     if (seconds < 1 && Cookies.get("end__")) {
//       Cookies.remove("end__");
//       setEnded(false);
//     }
//   }, [seconds]);

//   return (
//     <p
//       className={`resend text-center ${ended ? "text-muted" : ""}`}
//       onClick={!ended ? () => handleRunningFN() : () => {}}
//     >
//       RESEND OTP{" "}
//       {ended ? (
//         <>( {seconds > 0 ? `${seconds < 10 ? "0" : ""}${seconds}` : null}s)</>
//       ) : null}
//     </p>
//   );
// };

export default OtpComp;
