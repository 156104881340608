import { useEffect, useState } from "react";

//Utils
import Layout from "@components/layout";
import SEO from "@components/seo";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import nookies from "nookies";
import { AsyncAPI } from "src/lib/utils";

//Components
import { Button, HookForm, HookInput, Image } from "@components/anti/";
import {
  link_user_otp,
  link_user_pin,
  link_user_pin_create,
  link_user_register,
} from "@components/page_url";
// import UserLoginComp from "@components/pages/user-login";
import { Modal } from "@components/anti";
import OtpComp from "@components/pages/user-otp";
import ModalDeactivated from "src/global/modal/modal-deactivated";
import ModalVerifyAccount from "src/global/modal/modal-verify-account";
import { imgUrl, setStateFunc } from "src/lib/functions";
import { useStore } from "src/lib/store";
import ModalLogout from "src/global/modal/modal-logout";

const LoginPage = ({ width, listWidth, data }) => {
  //Utils **************************************************************** //
  const { user_data, setToast } = useStore((state) => state);
  const Router = useRouter();
  const modalOtpId = "modal-otp-login-id";
  //Utils **************************************************************** //

  //STATE **************************************************************** //
  const [state, setState] = useState({
    btnLoading: false,
    otpMessage: null,
    showOtpModal: false,
    phone: null,
    deactivated: {
      modal: false,
      message: "",
    },
    logout: {
      modal: false,
      message: "",
    },
  });
  const [modalVerify, setModalVerify] = useState({
    modal: false,
    message: "",
  });

  //STATE **************************************************************** //

  //FUNCTION ************************************************************* //
  const handleChangeState = (values) => setState({ ...state, ...values });
  const onSubmit = async (values) => {
    setState((prev) => ({ ...prev, btnLoading: true, phone: values.phone }));
    const { phone } = values;
    let sendPhone;
    if (phone[0] === "0") {
      sendPhone = phone.split("").splice(1, phone.length).join("");
    } else {
      sendPhone = phone;
    }
    try {
      let { data, errors } = await AsyncAPI({
        method: "POST",
        variables: {
          phone: sendPhone,
          country_code: "62",
        },
        url: "/check-phone",
      });
      Cookies.set(
        process.env.NEXT_PUBLIC_PHONE_USER,
        JSON.stringify({ phone, message: "", type: "login" }),
        {
          expires: 1 / 24,
        }
      );
      if (data?.success) {
        let { message } = data;
        //Set cookie exp 1 hour
        Cookies.set(
          process.env.NEXT_PUBLIC_PHONE_USER,
          JSON.stringify({ phone, message: "" }),
          {
            expires: 1 / 24,
          }
        );
        if (data?.state === "enter_pin") {
          Router.push(link_user_pin);
        }
        if (data?.state === "register") {
          Router.push(
            {
              pathname: link_user_register,
              query: { phone },
            },
            link_user_register
          );
        }
      } else {
        if (
          errors?.response?.data?.message.includes(
            "Please contact our customer service for reactivate your account"
          )
        ) {
          setStateFunc(
            {
              deactivated: {
                modal: "modal-deactivated",
                message: errors?.response?.data?.errors?.login,
              },
            },
            setState
          );
        } else if (
          errors?.response?.data?.message.includes(
            "Your account has been deactivated because there are too many wrong PIN entries"
          )
        ) {
          setModalVerify({
            modal: "modal-verify-account",
            message: errors?.response?.data?.message,
          });
        } else if (
          errors?.response?.data?.message ===
          "Please enter 6-digits PIN to login"
        ) {
          Router.push(link_user_pin);
        } else {
          Router.push(
            {
              pathname: link_user_register,
              query: { phone },
            },
            link_user_register
          );
        }
      }
    } catch (error) {
      //!NEED ERROR HANDLING
      console.log("error", error);
    } finally {
      setState((prev) => ({ ...prev, btnLoading: false }));
    }
  };

  const handleCallback = () => {
    handleChangeState({ showOtpModal: null });
    Router.push(link_user_pin_create);
    // window.location.reload();
  };

  const parsePhoneValue = (value) => {
    if (value) {
      if (value.length > 2 && value.startsWith("62"))
        return value.replace(value.substring(0, 2), "");
      if (value.startsWith("0")) return parseInt(value, 10);
      else return value;
    } else {
      return "";
    }
  };

  const handleForgot = async () => {
    let { data, errors } = await AsyncAPI({
      method: "POST",
      variables: {
        phone: state.phone,
        country_code: "62",
      },
      url: "/forgot-pin",
    });

    if (data) {
      Cookies.set(
        process.env.NEXT_PUBLIC_PHONE_USER,
        JSON.stringify({
          phone: data.phone,
          message: data.message,
          data: data.data,
          type: "login",
        }),
        {
          expires: 1 / 24,
        }
      );

      if (width > listWidth.md) {
        handleChangeState({
          showOtpModal: modalOtpId,
          otpMessage: data.message,
          phone: data.phone,
        });
      } else {
        Router.push(link_user_otp);
      }
    } else {
      setToast({
        isToast: true,
        message: errors?.response?.data?.message,
        status: "danger",
      });
    }
  };

  //FUNCTION ************************************************************* //

  //REACT OPS ************************************************************* //
  useEffect(() => {
    if (Router.query?.redirectAutoLogout) {
      setStateFunc(
        {
          logout: {
            modal: "modal-logout",
            message: "Woops, Your account has been logged in on another device",
          },
        },
        setState
      );
    }
  }, [Router]);
  //REACT OPS ************************************************************* //
  return (
    <>
      <SEO />
      <Modal
        className="modal-md centered py-5"
        id={modalOtpId}
        hide={() => handleChangeState({ showOtpModal: null })}
        isShowing={state.showOtpModal}
      >
        <OtpComp
          phone={state.phone}
          callback={handleCallback}
          message={state.otpMessage}
          className="py-3 mx-5"
        />
      </Modal>
      <ModalLogout
        id="modal-logout"
        isShowing={state.logout.modal}
        message={state.logout.message}
        hide={() =>
          setStateFunc({ logout: { modal: null, message: "" } }, setState)
        }
      />
      <ModalDeactivated
        id="modal-deactivated"
        isShowing={state.deactivated.modal}
        message={state.deactivated.message}
        hide={() =>
          setStateFunc({ deactivated: { modal: null, message: "" } }, setState)
        }
      />
      <ModalVerifyAccount
        id="modal-verify-account"
        isShowing={modalVerify.modal}
        message={modalVerify.message}
        onVerify={handleForgot}
        redirect={() => setModalVerify({ modal: null, message: "" })}
        hide={() => {
          setModalVerify({ modal: null, message: "" });
        }}
      />
      <Layout theme="yellow" className="no-border-sm" noNavbar>
        <section className="sc-login p-lg-0 fadeInUp">
          <div className="product-banner d-block d-md-none">
            <Image
              src={imgUrl(data?.meta?.banner?.img_mobile)}
              width={200}
              height={300}
            />
          </div>
          <div className="sc-login-form-box py-lg-5 py-3 container-lg">
            <div className="img-wrapper">
              <Image
                src="/img/logo/eatlah-club.png"
                className="img-fluid"
                width={150}
                height={40}
              />
            </div>
            <div
              className="label my-3"
              dangerouslySetInnerHTML={{ __html: data?.meta?.banner?.desc }}
            />
            <div className="form-box">
              <HookForm
                onSubmit={onSubmit}
                name="form-login-user"
                className="form-login-user"
                defaultValues={{ phone: "" }}
              >
                {(props) => {
                  return (
                    <>
                      <HookInput
                        {...props}
                        required
                        type="number"
                        name="phone"
                        className="mb-1"
                        prefix="+62"
                        label="Phone Number"
                        placeholder="Enter phone number"
                        validation={{
                          pattern: {
                            value: /^[0-9]*$/,
                            message: "Invalid phone number format",
                          },
                          minLength: {
                            value: 9,
                            message: "Min. 10 Character",
                          },
                          maxLength: {
                            value: 15,
                            message: "Max. 15 Character",
                          },
                        }}
                        onChange={(fieldName, value, setValue) => {
                          setValue(fieldName, parsePhoneValue(value));
                        }}
                        hideValidationText
                      />
                      <Button
                        className={`w-100 mt-3 mt-lg-2`}
                        type="submit"
                        loader={state.btnLoading}
                        // disabled={props.errors}
                      >
                        CONTINUE
                      </Button>
                    </>
                  );
                }}
              </HookForm>
            </div>
          </div>
          <div className="sc-login-image-login-desktop d-none d-md-block pointer-none">
            <Image src={imgUrl(data?.meta?.banner?.img_desktop)} fill />
          </div>
          <div className="site-by">
            Site By <Image src="/img/logo/antikode.png" width={56} height={8} />
          </div>
        </section>
      </Layout>
    </>
  );
};

export default LoginPage;

export const getServerSideProps = async (ctx) => {
  try {
    let token = nookies.get(ctx)[process.env.NEXT_PUBLIC_ACCESS_TOKEN];
    const [content] = await Promise.all([
      AsyncAPI({
        url: "/login",
        method: "GET",
        ctx,
        type: "content",
      }),
    ]);
    if (token) return { redirect: { destination: "/", permanent: true } };
    return {
      props: {
        data: content?.data?.data,
      },
    };
  } catch (error) {
    return { props: {} };
  }
};
