import { Modal, Image, Button } from "@components/anti";
import { Portal } from "react-portal";

const ModalLogout = ({
  id, //String
  isShowing, //Bool
  hide, //func
  message,
}) => {
  return (
    <>
      {id === isShowing ? (
        <Portal node={document?.getElementById("deactivated-portal")}>
          <div className="deactivated-portal-class fadeInUp">
            <div className="overlay" onClick={() => hide()}></div>
            <div className="deactivated-info-wrapper mt-5">
              <div className="container mw-sm">
                <div className="deactivated-info-box">
                  <Image
                    src={"/img/common/stamp-failed.png"}
                    width={140}
                    height={140}
                  />
                  <p className="mb-5">
                    Woops, Your account has been logged in on another device
                  </p>
                  <Button className="w-100" onClick={() => hide()}>
                    Confirm
                  </Button>
                </div>
                <div className={`close-wrapper`}>
                  <div className="icon" onClick={() => hide()}>
                    <i className="air ai-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default ModalLogout;
