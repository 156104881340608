import { Modal, Image, Button } from "@components/anti";
import { Portal } from "react-portal";

const ModalVerifyAccount = ({
  id, //String
  isShowing, //Bool
  hide, //func
  message,
  redirect,
  onVerify,
}) => {
  return (
    <>
      {id === isShowing ? (
        <Portal node={document?.getElementById("verify-account-portal")}>
          <div className="verify-account-portal-class fadeInUp">
            <div className="overlay"></div>
            <div className="verify-account-info-wrapper mt-5">
              <div className="container mw-sm">
                <div className="verify-account-info-box">
                  <Image
                    src={"/img/common/stamp-failed.png"}
                    width={140}
                    height={140}
                  />
                  {/* <div dangerouslySetInnerHTML={{ __html: message }}></div> */}
                  <p>
                    Your account has been deactivated because there are{" "}
                    <b>too many wrong PIN entries.</b>
                  </p>
                  <p className="mb-5">
                    Please verify your phone number to reactivate your account.
                  </p>

                  <Button
                    className="w-100"
                    onClick={() => {
                      hide();
                      setTimeout(() => {
                        onVerify();
                      }, 100);
                    }}
                  >
                    Verify Account
                  </Button>
                </div>
                <div className={`close-wrapper`}>
                  <div className="icon" onClick={() => redirect()}>
                    <i className="air ai-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

export default ModalVerifyAccount;
